// Hol alle Radio-Inputs
const frequencyInputs = document.querySelectorAll('input[name="frequency"]');
const priceElements = document.querySelectorAll('.price');
const contractTermElements = document.querySelectorAll('.contract-term'); // Elemente mit der Mindestlaufzeit

// Funktion zum Aktualisieren der Preise
function updatePrices(frequency) {
  priceElements.forEach(priceElement => {
    const monthlyPrice = priceElement.getAttribute('data-monthly');
    const lumpsumPrice = priceElement.getAttribute('data-lumpsum');

    if (frequency === 'lumpsum') {
      priceElement.innerHTML = `ab €${lumpsumPrice}<span class="text-base font-normal"></span>`;
    } else {
      priceElement.innerHTML = `ab €${monthlyPrice}<span class="text-base font-normal">/Monat</span>`;
    }
  });

  // Mindestlaufzeit anzeigen oder ausblenden
  contractTermElements.forEach(contractTerm => {
    if (frequency === 'lumpsum') {
      contractTerm.style.display = 'none';
    } else {
      contractTerm.style.display = 'block';
    }
  });
}

// Event-Listener für die Radio-Buttons hinzufügen
frequencyInputs.forEach(input => {
  input.addEventListener('change', (e) => {
    updatePrices(e.target.value);
  });
});

// Initiale Preise setzen (basierend auf der Voreinstellung)
const checkedInput = document.querySelector('input[name="frequency"]:checked');
if (checkedInput) {
  updatePrices(checkedInput.value);
}
